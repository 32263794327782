import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Loe edasi

  <span style="color:#85c1dc;">**FI:**</span>
  Lue eteenpäin

  <span style="color:#85c1dc;">**RU:**</span>
  Читай дальше
*/
/* @__NO_SIDE_EFFECTS__ */
const m532 = msg(
  mf`Loe edasi`,
  mf`Lue eteenpäin`,
  mf`Читай дальше`,
)

export { m532 as 'w.global.read_more' }