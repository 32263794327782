import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Astu meie sekka ja naudi püsikliendina kontoraha abil lisasoodustusi tavahinnaga toodetelt kuni -10%.

  <span style="color:#85c1dc;">**FI:**</span>
  Liity joukkoomme ja saat ON24-asiakkaana lisäalennuksia ON24-rahalla tuotteista jopa -10%.

  <span style="color:#85c1dc;">**RU:**</span>
  Присоединяйтесь к нам и получайте дополнительные скидки до -10%  как постоянный клиент за деньги на счету клиента.
*/
/* @__NO_SIDE_EFFECTS__ */
const m285 = msg(
  mf`Astu meie sekka ja naudi püsikliendina kontoraha abil lisasoodustusi tavahinnaga toodetelt kuni -10%.`,
  mf`Liity joukkoomme ja saat ON24-asiakkaana lisäalennuksia ON24-rahalla tuotteista jopa -10%.`,
  mf`Присоединяйтесь к нам и получайте дополнительные скидки до -10%  как постоянный клиент за деньги на счету клиента.`,
)

export { m285 as 'w.module.newsletter_form_modal.text' }