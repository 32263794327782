import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Olen lugenud ja nõustun &lt;a href='\#' target='\_blank'&gt;privaatsustingimustega&lt;/a&gt;

  <span style="color:#85c1dc;">**FI:**</span>
  Olen lukenut ja hyväksyn&lt;a href='\#' target='\_blank'&gt;tietosuojaehdot&lt;/a&gt;

  <span style="color:#85c1dc;">**RU:**</span>
  Я прочитал и согласен с &lt;a href='\#' target='\_blank'&gt;условиями конфиденциальности&lt;/a&gt;
*/
/* @__NO_SIDE_EFFECTS__ */
const m1083 = msg(
  mf`Olen lugenud ja nõustun <a href='#' target='_blank'>privaatsustingimustega</a>`,
  mf`Olen lukenut ja hyväksyn<a href='#' target='_blank'>tietosuojaehdot</a>`,
  mf`Я прочитал и согласен с <a href='#' target='_blank'>условиями конфиденциальности</a>`,
)

export { m1083 as 'w.module.newsletter_form_modal.checkbox_text' }