import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Saatsime Teie e-posti aadressile salasõna.Palun kontrollige oma saabunud e-maile.

  <span style="color:#85c1dc;">**FI:**</span>
  Lähetimme sähköpostiisi salasanan. Tarkista saapuneet viestit.

  <span style="color:#85c1dc;">**RU:**</span>
  Мы отправили пароль на Ваш адрес электронной почты, пожалуйста, проверьте свой почтовый ящик.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1041 = msg(
  mf`Saatsime Teie e-posti aadressile salasõna.Palun kontrollige oma saabunud e-maile.`,
  mf`Lähetimme sähköpostiisi salasanan. Tarkista saapuneet viestit.`,
  mf`Мы отправили пароль на Ваш адрес электронной почты, пожалуйста, проверьте свой почтовый ящик.`,
)

export { m1041 as 'w.module.newsletter_form_modal.success_text' }