import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Salasõna

  <span style="color:#85c1dc;">**FI:**</span>
  Salasana

  <span style="color:#85c1dc;">**RU:**</span>
  Пароль
*/
/* @__NO_SIDE_EFFECTS__ */
const m186 = msg(
  mf`Salasõna`,
  mf`Salasana`,
  mf`Пароль`,
)

export { m186 as 'module.newsletter_form_modal.password' }