import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Email

  <span style="color:#85c1dc;">**FI:**</span>
  Viesti

  <span style="color:#85c1dc;">**RU:**</span>
  Электронная почта
*/
/* @__NO_SIDE_EFFECTS__ */
const m717 = msg(
  mf`Email`,
  mf`Viesti`,
  mf`Электронная почта`,
)

export { m717 as 'module.newsletter_form_modal.email' }