import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Siin on kingituseks 30 eurot!

  <span style="color:#85c1dc;">**FI:**</span>
  Tässä sinulle lahjaksi 30 euroa!

  <span style="color:#85c1dc;">**RU:**</span>
  Вот подарок 30 евро для вас!
*/
/* @__NO_SIDE_EFFECTS__ */
const m651 = msg(
  mf`Siin on kingituseks 30 eurot!`,
  mf`Tässä sinulle lahjaksi 30 euroa!`,
  mf`Вот подарок 30 евро для вас!`,
)

export { m651 as 'w.module.newsletter_form_modal.title' }