<template>
  <div v-if="!user" class="relative overflow-hidden bg-background-dark px-3 md:px-6">
    <div class="h-container relative max-w-[1650px] pb-16 pt-48 text-center sm:pt-56 md:flex md:items-center md:py-12 md:text-left">
      <div
        class="absolute -top-6 left-2/4 h-[168px] w-[422px] -translate-x-2/4 bg-bottom bg-no-repeat sm:-top-10 sm:h-[230px] sm:w-[570px] md:-bottom-3 md:left-[38%] md:top-auto md:translate-x-0 xl:left-[42%]"
        :style="`background-image: url(${backgroundImage})`"
      />
      <div class="relative z-10 mx-auto max-w-xl md:mx-0 md:max-w-none">
        <h2 class="text-h1">{{ t['w.module.cta_banner.title']() }}</h2>
        <p v-if="t['w.module.cta_banner.description']()" class="mt-6 text-h2 md:mt-2 md:text-lg md:font-normal">{{ t['w.module.cta_banner.description']() }}</p>
      </div>
      <Button
        class="btn-rounded mt-12 md:ml-auto md:mt-0 md:h-0"
        :title="t['w.module.cta_banner.action.text']()"
        icon-name="arrow-right"
        @click="loginModalStore.open(t['w.login.welcome'](), 'register')"
      >
        {{ t['w.module.cta_banner.action.text']() }}
      </Button>
    </div>
    <div class="bg-gradient-to-r from-background-dark from-50% md:absolute md:left-0 md:top-0 md:h-full md:w-[720px]" />
    <NewsletterFormModal ref="modal" :image="modalFirstImage" :image-success="modalSuccessImage" />
  </div>
</template>

<script setup lang="ts">
import NewsletterFormModal from '~/components/newsletter-form-modal/NewsletterFormModal.vue'
import { useNewsletterState, useUserState } from '~/composables/states'
import { useLoginModalStore } from '~/stores/login-modal'
import backgroundImage from '~/assets/illustrations/deal.svg'
import * as t from '~/messages'

const modal = useTemplateRef('modal')

const newsletterState = useNewsletterState()
const loginModalStore = useLoginModalStore()
const modalFirstImage = newsletterState.value?.formModalImage
const modalSuccessImage = newsletterState.value?.formModalImage

const user = useUserState()
</script>
