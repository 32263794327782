import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  või liitu e-postiga

  <span style="color:#85c1dc;">**FI:**</span>
  tai liity sähköpostitse

  <span style="color:#85c1dc;">**RU:**</span>
  или присоединяйтесь по электронной почте
*/
/* @__NO_SIDE_EFFECTS__ */
const m1509 = msg(
  mf`või liitu e-postiga`,
  mf`tai liity sähköpostitse`,
  mf`или присоединяйтесь по электронной почте`,
)

export { m1509 as 'w.module.newsletter_form_modal.divider_text' }