import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisesta oma parool

  <span style="color:#85c1dc;">**FI:**</span>
  Syötä salasanasi

  <span style="color:#85c1dc;">**RU:**</span>
  Введите свой пароль
*/
/* @__NO_SIDE_EFFECTS__ */
const m625 = msg(
  mf`Sisesta oma parool`,
  mf`Syötä salasanasi`,
  mf`Введите свой пароль`,
)

export { m625 as 'w.module.newsletter_form_modal.password_placeholder' }