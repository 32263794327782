import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Hakka püsikliendiks ja kanname ON24 raha sinu kliendikontole, mida saad kasutada lisasoodustuse saamiseks.

  <span style="color:#85c1dc;">**FI:**</span>
  Tule ON24 asiakkaaksi ja siirrämme rahaa ON24 tilillesi.

  <span style="color:#85c1dc;">**RU:**</span>
  Станьте постоянным клиентом и мы переведем деньги на ваш счет клиента ON24.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1282 = msg(
  mf`Hakka püsikliendiks ja kanname ON24 raha sinu kliendikontole, mida saad kasutada lisasoodustuse saamiseks.`,
  mf`Tule ON24 asiakkaaksi ja siirrämme rahaa ON24 tilillesi.`,
  mf`Станьте постоянным клиентом и мы переведем деньги на ваш счет клиента ON24.`,
)

export { m1282 as 'w.module.cta_banner.description' }