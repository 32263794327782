import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Aitäh, et meiega liitusid!

  <span style="color:#85c1dc;">**FI:**</span>
  Kiitos, et liityit!

  <span style="color:#85c1dc;">**RU:**</span>
  Спасибо, что присоединились к нам!
*/
/* @__NO_SIDE_EFFECTS__ */
const m843 = msg(
  mf`Aitäh, et meiega liitusid!`,
  mf`Kiitos, et liityit!`,
  mf`Спасибо, что присоединились к нам!`,
)

export { m843 as 'w.module.newsletter_form_modal.success_title' }