import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kliki siia ja võta raha

  <span style="color:#85c1dc;">**FI:**</span>
  Klikkaa tästä ja hanki rahaa

  <span style="color:#85c1dc;">**RU:**</span>
  Нажми здесь и получи деньги
*/
/* @__NO_SIDE_EFFECTS__ */
const m365 = msg(
  mf`Kliki siia ja võta raha`,
  mf`Klikkaa tästä ja hanki rahaa`,
  mf`Нажми здесь и получи деньги`,
)

export { m365 as 'w.module.cta_banner.action.text' }