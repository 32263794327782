import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Loe vähem

  <span style="color:#85c1dc;">**FI:**</span>
  Lue vähemmän

  <span style="color:#85c1dc;">**RU:**</span>
  Читать меньше
*/
/* @__NO_SIDE_EFFECTS__ */
const m680 = msg(
  mf`Loe vähem`,
  mf`Lue vähemmän`,
  mf`Читать меньше`,
)

export { m680 as 'w.global.read_less' }