import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Uuele liitujale 20 € ON24 raha kätte.

  <span style="color:#85c1dc;">**FI:**</span>
  Uudelle liittyjälle 20 €.

  <span style="color:#85c1dc;">**RU:**</span>
  Новым подписчикам 20 €.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1485 = msg(
  mf`Uuele liitujale 20 € ON24 raha kätte.`,
  mf`Uudelle liittyjälle 20 €.`,
  mf`Новым подписчикам 20 €.`,
)

export { m1485 as 'w.module.cta_banner.title' }