import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Hakkan püsikliendiks

  <span style="color:#85c1dc;">**FI:**</span>
  Liityn ON24-asiakkaaksi

  <span style="color:#85c1dc;">**RU:**</span>
  Стать постоянным клиентом
*/
/* @__NO_SIDE_EFFECTS__ */
const m973 = msg(
  mf`Hakkan püsikliendiks`,
  mf`Liityn ON24-asiakkaaksi`,
  mf`Стать постоянным клиентом`,
)

export { m973 as 'w.module.newsletter_form_modal.button_text' }