import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sinu e-posti aadress

  <span style="color:#85c1dc;">**FI:**</span>
  Sähköpostiosoitteesi

  <span style="color:#85c1dc;">**RU:**</span>
  Ваш электронный адрес
*/
/* @__NO_SIDE_EFFECTS__ */
const m681 = msg(
  mf`Sinu e-posti aadress`,
  mf`Sähköpostiosoitteesi`,
  mf`Ваш электронный адрес`,
)

export { m681 as 'w.module.newsletter_form_modal.email_placeholder' }